import { colours, fontSizes, customFonts } from "../../constants/css";
import styled from 'styled-components';
var arrow = "/static/link-arrow.svg";
export var BoxContainer = styled.div.withConfig({
  displayName: "style__BoxContainer",
  componentId: "sc-jso7d8-0"
})(["background-color:", ";border-radius:4px;padding:1.1rem;width:263px;min-height:290px;text-align:left;h3{margin:0;font-family:", ";font-size:", ";font-weight:normal;}a{color:", ";}ul{margin:1.25rem 0 2rem;padding-left:1rem;li{margin-bottom:1rem;line-height:1;}}"], colours.qantasLightAqua, customFonts.ciutadellaMed, fontSizes.xl, colours.qantasRed);
export var TooltipContainer = styled.div.withConfig({
  displayName: "style__TooltipContainer",
  componentId: "sc-jso7d8-1"
})(["position:relative;text-align:center;"]);

// TODO: replace tooltip image with icon font
export var TooltipTitle = styled.div.withConfig({
  displayName: "style__TooltipTitle",
  componentId: "sc-jso7d8-2"
})(["display:inline-flex;align-items:center;cursor:pointer;> span{border-bottom:1px dashed;}> img{width:16px;height:17px;margin-right:6px;}"]);
export var TooltipContent = styled.div.withConfig({
  displayName: "style__TooltipContent",
  componentId: "sc-jso7d8-3"
})(["position:absolute;top:2.5rem;z-index:9999;box-shadow:0 2px 4px 0 rgba(0,0,0,0.2),0 18px 17px 0 rgba(0,0,0,0.05);border-radius:4px;&:before{display:inline-block;content:'';position:absolute;top:0;left:", ";transform:translateY(-100%);border-left:12px solid transparent;border-right:12px solid transparent;border-bottom:12px solid ", ";}", "{padding:24px 15px;width:199px;min-height:238px;ul{margin:1.25rem 0;li{font-size:", ";}}}"], function (props) {
  return props.position ? '50%' : '10px';
}, colours.qantasLightAqua, BoxContainer, fontSizes.small);
export var RightArrow = styled.i.withConfig({
  displayName: "style__RightArrow",
  componentId: "sc-jso7d8-4"
})(["display:inline-block;background:url(", ");background-repeat:no-repeat;width:1rem;height:7px;"], arrow);